import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  grid grid-cols-2 gap-x-2-4 mb-4-5
`

export const Button = styled.button`
  ${tw`border border-black py-1-2 px-1-4 md:px-1-8 flex items-center md:justify-start rounded md:hover:bg-black md:hover:text-white`}
  span {
    ${tw`text-14 leading-1.78`}
    :last-of-type {
      ${tw`pl-0-8 md:pl-3`}
    }
  }
`

export const Alternative = styled.div`
  ${tw`h-0-1 border-t border-black border-opacity-25 text-center mb-4`}
  span {
    ${tw`relative px-2 -top-1 bg-beige-light`}
  }
`
